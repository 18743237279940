
@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide > div{
  margin: 0 10px;
}
.bod{
 border: 2px solid blue;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;

  background-color: green;
}
.flex{

  display: flex;
  justify-content: space-between;
  
  align-items: center;
  margin-left: 20px;
  margin-right: 30px;


}


@media only screen and (max-width: 600px) {
  .flex{
    display: flex;
    flex-direction: column;
    gap:10px;
  }
  .stat{
    display: flex;
    flex-direction: column;
    justify-content: center;
    

  }
  }
  .reviews{
    background:linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url("download.jpg");
    background-size: cover;
    background-position:center ;
    background-attachment: fixed;
    width: auto;
    height: 300px;
    padding: 30px;
  }
  .box{
    box-shadow: 2px 0 2px 0 white;
  }