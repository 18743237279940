@import url("typography.css");
:root {
  --primary-color: #010e44;
  --primary-color-2: #001b87;
  --secondary-color: #0bce83;
}

.newHeroBg {
  background: linear-gradient(rgba(38, 38, 38, 0.5), rgba(38, 38, 38, 0.5)),
    url("./Assets/hero.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.gradient-text {
  background: linear-gradient(180deg, #009a49 0%, #ffc400 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gradient-text2 {
  background: linear-gradient(180deg, #009a49 50%, #ffc400 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color-2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}

.accordion-button {
  box-shadow: none !important;
}

button,
button:focus,
input,
input:focus,
*:focus {
  outline: none !important;
  box-shadow: none !important;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0 !important;
  box-shadow: none !important;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  box-sizing: border-box;
  font-size: 14px;
}

body {
  overflow-x: hidden;
}

.item {
  border-radius: 10px;
  border: 2px solid #022288;
  padding: 10px 20px;
  margin-bottom: 5px;
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: "#022288";
  font-weight: bold;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content-show {
  height: auto;
  font-family: "Poppins";
  margin-top: 10px;
  color: "#3e4145";
  max-height: 999;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.fontInherit {
  font-size: inherit !important;
}

#main-wrapper {
  background: #fff;
}

/*== Side Header ==*/

.side-header #main-wrapper {
  margin-left: 260px;
}

.fix {
  /* position: fixed; */
}

.side-header .sidebar-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 260px;
  height: 100% !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.748);
  z-index: 2;
  overflow: hidden;
  overflow-y: auto;
}

.covercities {
  overflow: hidden;
}

.marqueeformat {
  -moz-animation: marquee 60s linear infinite;
  -webkit-animation: marquee 60s linear infinite;
  animation: marquee 60s linear infinite;
}

.marqueeformat > div {
  white-space: nowrap;
}

@-moz-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-500%);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-500%);
  }
}

@keyframes marquee {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-500%);
    -webkit-transform: translateX(-500%);
    transform: translateX(-500%);
  }
}

.side-header ul.navbar-nav li a:not(.btn),
.side-header ul.navbar-nav li > span {
  display: block;
  height: auto;
}

.side-header ul.navbar-nav > li > a:not(.btn),
.side-header ul.navbar-nav li > span {
  height: auto;
  padding: 10px 0;
  position: relative;
}

.mainSection {
  display: grid;
  gap: 1rem;
  grid-template-columns: 2.5fr 1.5fr;
}

@media (max-width: 991.98px) {
  .side-header #main-wrapper {
    margin-left: auto;
  }
  .side-header .sidebar-menu {
    position: initial;
    width: 100%;
    height: auto !important;
    overflow: visible;
  }
  .mainSection {
    grid-template-columns: 1fr !important;
  }
}

.myCursor {
  cursor: pointer;
}

.poppins {
  font-family: "Poppins" !important;
}

.headerActive,
.textColor2 {
  color: var(--primary-color-2) !important;
}

.textColor {
  color: var(--primary-color) !important;
}

.logo {
  width: 100%;
  height: 90px;
}

.logo2 {
  width: 80px;
}

.btn-primary1 {
  background-color: var(--primary-color-2) !important;
  color: #fff !important;
  transition: all 1s ease-in-out;
}

.btn-outline-primary1 {
  border-color: var(--primary-color-2) !important;
  color: var(--primary-color-2) !important;
  transition: all 1s ease-in-out;
}

.btn-primary3 {
  background-color: var(--primary-color) !important;
  color: #fff !important;
  transition: all 1s ease-in-out;
}

.btn-outline-primary3 {
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
  transition: all 1s ease-in-out;
}

.btn-primary1:hover {
  transition: all 1s ease-in-out;
  background-color: var(--primary-color-2) !important;
}

.btn-outline-primary1:hover {
  transition: all 1s ease-in-out;
  color: #fff !important;
  background-color: var(--primary-color-2) !important;
}

.borderColor {
  border-color: #292929 !important;
}

.borderColor2 {
  border: 1px solid var(--primary-color-2) !important;
  border-radius: 20px !important;
}

.text5 {
  font-size: 55px;
  font-weight: 600;
}

.fullscreen {
  height: 85vh !important;
}

.aboutScreen {
  min-height: 90vh !important;
  height: 100% !important;
}

.minFullHeight {
  min-height: 80vh !important;
}

.borderDash {
  border-top: 1px dashed var(--primary-color-2);
}

.bg-select {
  background: var(--primary-color);
}

.bg-select-2 {
  background: var(--primary-color-2);
}

.midTransition {
  top: -1.5rem;
  left: 45%;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary-color);
  color: white;
  outline: 1px solid var(--secondary-color) !important;
  border: 4px solid #fff !important;
}

.startTransition {
  top: 0;
  left: -8.5px;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: rgba(0, 27, 135, 0.5);
}

.startTransition2 {
  background: var(--primary-color-2);
}

.downH {
  height: 25rem;
  border-radius: 50px !important;
}

.downH2 {
  min-height: 25rem;
}

.downH3 {
  min-height: 30rem;
}

.phoneTab {
  right: 5rem;
  height: 35rem;
  top: -5rem;
}

.mainDownH {
  height: 80vh;
}

.text2 {
  font-size: 3rem;
}

.citiesCoverage {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
  place-items: center;
}

.mustSeperate {
  height: 20px;
}

.modal .btn-close {
  height: 10px !important;
  width: 10px !important;
  padding: 2px !important;
  margin-right: 0.2rem !important;
}

.reqAccVer .modal-title {
  font-size: 15px !important;
}

.borderNone {
  border: none !important;
}

.bg-notify {
  background: hsla(157, 90%, 43%, 0.203) !important;
}

.ourGoal::-webkit-scrollbar,
.mainGoal::-webkit-scrollbar {
  height: 0 !important;
  width: 0 !important;
}

.mainGoal {
  overflow-x: scroll;
}

.ourGoal {
  display: flex !important;
  @apply tw-gap-16;
  overflow-x: scroll !important;
  width: 200% !important;
}

.ourGoal .offerBox {
  width: 30rem !important;
  text-align: center;
  height: 15rem;
}

.imgTestimony {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}

.timeBoard {
  border-radius: 100% !important;
  width: 7rem !important;
  height: 7rem !important;
  z-index: 100;
  right: 1rem;
}

.textTrunc {
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.textTrunc2 {
  -webkit-line-clamp: 2;
}

.textTrunc3 {
  -webkit-line-clamp: 3;
}

.textTrunc4 {
  -webkit-line-clamp: 4;
}

.textTrunc5 {
  -webkit-line-clamp: 5;
}

.footerDiv {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.altAbout {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  gap: 1rem;
}

.altImg {
  height: 100%;
  width: 100%;
  max-height: 80vh;
  object-fit: cover;
  object-position: center;
}

.aboutImg {
  object-fit: contain;
  object-position: top;
}

.fw-600 {
  font-weight: 600 !important;
}

.table th,
.table td {
  vertical-align: middle;
}

.table th {
  text-transform: capitalize;
  font-weight: 600;
  padding: 10px;
}

.table {
  width: 100%;
  margin-bottom: 30px;
}

.table tbody tr,
.thead {
  border: none;
}

.table *:not(:first-child) {
  border-top: none !important;
}

.table * {
  border-bottom-width: 0 !important;
}

.thead,
.tr {
  height: 70px;
}

.tr {
  height: 90px;
}

.trFull {
  height: 100%;
  min-height: 90px;
}

.bg-grey {
  background: #f4f8fd !important;
}

.show-hide span {
  z-index: 5;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.otp-code__input {
  border-radius: 0.5rem;
  font-family: monospace;
  height: 50px;
  border: 1px solid #ccc;
  font-size: 2rem;
  text-align: center;
  transition: all 0.2s ease;
  color: #222;
  width: 3rem !important;
  margin: 0 0.5rem;
}

ol.listStyleRoman::before {
  list-style-type: lower-alpha !important;
}

.emptyData {
  max-height: 50vh;
}

.notificationSound {
  display: none;
}

.mainTabs {
  height: 65px;
}

.chatScreen {
  height: 66vh;
  overflow-y: auto;
}

.file_upload {
  overflow: hidden;
  position: relative;
  margin: 0;
}

.file_upload #file {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  opacity: 0;
}

.EachImgClose {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 455;
  background: white;
  color: crimson;
  padding: 0 3px 1.5px;
  place-items: center;
  border: 1px solid crimson;
  font-size: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.headerScroll.w-100 {
  width: 100% !important;
}

@media screen and (max-width: 991.98px) {
  .logo {
    width: 50px;
    height: 60px;
  }
  .logo2 {
    width: 60px;
  }
  .phoneTab {
    right: 1rem;
    height: 30rem;
    top: -10%;
  }
  .mainDownH h2 {
    font-size: 30px;
  }
  .fontReduce2 {
    font-size: 14px;
  }
  .ourGoal {
    width: 300% !important;
  }
  .fullWidth.w-75 {
    width: 100% !important;
  }
  .fullscreen {
    height: 75vh !important;
  }
}

@media screen and (max-width: 767.99px) {
  .w75.w-75 {
    width: 100% !important;
  }
  .fullOverflow {
    overflow-x: hidden;
  }
  .textDefault {
    font-size: 16px;
  }
  .w50.w-50 {
    width: 75% !important;
  }
  .downH.downMini {
    height: 15rem;
  }
  thead tr.trFull th {
    font-size: 12px !important;
  }
  .text5 {
    font-size: 30px;
  }
  .ourGoal {
    width: 400% !important;
  }
  .footerDiv {
    grid-template-columns: repeat(2, 1fr);
  }
  .borderDash {
    border-top: none;
  }
  .borderDash2 {
    position: absolute;
    left: 4%;
    height: 100%;
    border-left: 1px dashed var(--primary-color-2);
  }
  .altImg {
    max-height: 60vh;
  }
  .midTransition {
    left: 0;
    top: 0;
  }
  .emptyData {
    max-height: 25vh;
  }
  .citiesCoverage {
    grid-template-columns: repeat(3, 1fr);
  }
  .fullscreen,
  .mainDownH.mainDownH2 {
    height: 60vh !important;
  }
  .hero h1,
  .mainDownH h2 {
    font-size: 30px;
  }
  .mainDownH2 h2 {
    font-size: 18px;
  }
  .hero .w-75,
  .w-75.fullwidth {
    width: 100% !important;
  }
  .hero p,
  .full-justify {
    text-align: justify !important;
  }
  .fontReduce {
    font-size: 14px !important;
  }
  .text-center.textCenter {
    text-align: left !important;
  }
  .mainDownH.mainDownH2 .mainDownH3 {
    height: 50vh !important;
  }
  .my-auto.downLoadDown {
    margin-top: auto !important;
    margin-bottom: 1rem !important;
    text-align: center;
  }
  .phoneTab {
    transform: translate(-50%, -50%) !important;
    left: 50%;
    height: 12rem;
    top: 40%;
  }
  .mainDownH2 p {
    font-size: 8px !important;
  }
  @-moz-keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(1800%);
    }
  }
  @-webkit-keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-1800%);
    }
  }
  @keyframes marquee {
    0% {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    100% {
      -moz-transform: translateX(-1800%);
      -webkit-transform: translateX(-1800%);
      transform: translateX(-1800%);
    }
  }
  .otp-code__input {
    height: 30px;
    width: 25px !important;
  }
  .otp_div span {
    display: none !important;
  }
  .fontReducePass {
    font-size: 11px;
  }
  .fontReduce13 {
    font-size: 16px;
  }
}

@media screen and (max-width: 470px) {
  .my-auto.downLoadDown {
    margin-bottom: 1rem !important;
  }
  .ourGoal {
    width: 500% !important;
  }
  .borderDash2 {
    left: 5%;
  }
  .fontReduceMaxhead {
    font-size: 12px !important;
  }
  .fontReduceMax {
    font-size: 10px !important;
  }
  .text5.mainText {
    font-size: 22px;
  }
}

@media screen and (max-width: 400px) {
  .ourGoal {
    width: 600% !important;
  }
  .borderDash2 {
    left: 6%;
  }
  .fullOverflow .container.my-auto {
    margin-top: 1.5rem !important;
  }
}

@media screen and (max-width: 340px) {
  .borderDash2 {
    left: 7%;
  }
  .fullOverflow.fullscreen {
    min-height: 70vh !important;
  }
}

@media screen and (min-width: 1500px) {
  .aboutScreen {
    min-height: 70vh !important;
  }
}

@media screen and (min-height: 768px) {
  .aboutScreen {
    min-height: 50vh !important;
  }
  .fullscreen {
    height: 45vh !important;
  }
  /* .mainDownH.mainDownH2 {
		height: 20vh !important;
	} */
  .headerScroll.w-100 {
    width: 100% !important;
  }
}

.table > :not(caption) > * > * {
  background: transparent !important;
}

.d-f {
  display: flex !important;
}
