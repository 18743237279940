@font-face {
  font-family: Poppins;
  font-weight: 300;
  font-style: normal;
  src: url("./Assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 400;
  font-style: normal;
  src: url("./Assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  src: url("./Assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins;
  font-weight: 600;
  font-style: normal;
  src: url("./Assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  font-style: normal;
  src: url("./Assets/fonts/Poppins-Bold.ttf");
}
